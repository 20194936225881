@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.error-container {
  flex: auto;
  text-align: center;
}

/* FONTS */

body,
html {
  font-family: 'Lato', sans-serif;
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 14px;
}

input, button, select, option {
    font-size: 1rem;
}